import React from 'react';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import './SignInSide.css';
import { defaultTheme } from '../utils/constants';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IconButton } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.chairchart.com">
        ChairChart
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// function parseError(error) {
//   console.log(error)
// }

// async function signInWithEmail(event) {
//     event.preventDefault();
//     const loginData = new FormData(event.currentTarget);
//     // Add in a catch statement
//     const { data, error } = await supabase.auth.signInWithPassword({
//       email: loginData.get('email'),
//       password: loginData.get('password'),
//     })
//     parseError(error)
//   }

// async function newUserWithEmail(event) {
//   event.preventDefault();
//   const signupData = new FormData(event.currentTarget);
//   // add in a catch statement
//   const { data, error } = await supabase.auth.signUp(
//     {
//       email: signupData.get('email'),
//       password: signupData.get('password'),
//       options: {
//         data: {
//           first_name: signupData.get('firstName'),
//           last_name: signupData.get('lastName'),
//           testArray: ['hello', 'there']
//         }
//       }
//     }
//   )
// }

// function SignInComponent({setIsLogin}) {
//   return(
//     <>
//       <Typography component="h1" variant="h5">
//             Sign in
//       </Typography>
//       <Box component="form" onSubmit={signInWithEmail} noValidate sx={{ mt: 1 }}>
//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           id="email"
//           label="Email Address"
//           name="email"
//           autoComplete="email"
//           autoFocus
//         />
//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           name="password"
//           label="Password"
//           type="password"
//           id="password"
//           autoComplete="current-password"
//         />
//         <FormControlLabel
//           control={<Checkbox value="remember" color="primary" />}
//           label="Remember me"
//         />
//         <Button
//           type="submit"
//           fullWidth
//           variant="contained"
//           sx={{ mt: 3, mb: 2 }}
//         >
//           Sign In
//         </Button>
//         <Grid container>
//           <Grid item xs>
//             <Link variant="body2">
//               Forgot password?
//             </Link>
//           </Grid>
//           <Grid item>
//             <Link onClick={()=>setIsLogin(false)} href="#" variant="body2">
//               {"Don't have an account? Sign Up"}
//             </Link>
//           </Grid>
//         </Grid>
//     </Box>
//   </>
//   )
// }

// function SignUpComponent({setIsLogin}) {
//   return(
//     <>
//       <Typography component="h1" variant="h5">
//             Sign Up
//       </Typography>
//       <Box component="form" noValidate onSubmit={newUserWithEmail} sx={{ mt: 3 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 autoComplete="given-name"
//                 name="firstName"
//                 required
//                 fullWidth
//                 id="firstName"
//                 label="First Name"
//                 autoFocus
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 required
//                 fullWidth
//                 id="lastName"
//                 label="Last Name"
//                 name="lastName"
//                 autoComplete="family-name"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="new-password"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={<Checkbox value="allowExtraEmails" color="primary" />}
//                 label="I want to receive inspiration, marketing promotions and updates via email."
//               />
//             </Grid>
//           </Grid>
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{ mt: 3, mb: 2 }}
//           >
//             Sign Up
//           </Button>
//           <Grid container justifyContent="flex-end">
//             <Grid item>
//               <Link onClick={()=>setIsLogin(true)} href="#" variant="body2">
//                 Already have an account? Sign in
//               </Link>
//             </Grid>
//           </Grid>
//         </Box>
//     </>
//   )
// }

export default function SignInSide() {
  const navigate = useNavigate();

  supabase.auth.onAuthStateChange(async (event) =>{
    console.log(event)
    if (event === "SIGNED_IN") {
      navigate('/');
    } 
  })

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random/?classrooms)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} direction="column" alignItems="center" square>
          <IconButton className="back-button" id="login-back-button" onClick={()=>navigate('/')}>
              <BsFillArrowLeftCircleFill width="40px"/>
          </IconButton>
          <div className="login--container">
            <Typography component="h1" variant="h5">
                Create amazing seating charts!
            </Typography>
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              providers={['google']}
              />
            <Copyright />
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
