import React, {useEffect, useState} from 'react'
import './Grid.css'
import { ellipseWidth, ellipseHeight, ellipseGap, padding } from '../../utils/constants'

function Ellipse() {
    return (
      <div className="ellipse"></div>
    )
}

function Row({width}) {
    let row = []
    let numEllipses = (width - 2 * padding) / (ellipseGap + ellipseWidth)
    
    for (let j = 0; j < numEllipses; j++){
        row.push(<Ellipse key={j}/>)
    }
    return (
        <div className="row">
            {row}
        </div>
    )
}

function Grid({state, seatArray, setSeatArray}) {
    const [windowSize, setWindowSize] = useState([
      window.innerWidth,
      window.innerHeight,
    ]);

    /**
     * Updates Window Size if resized
     */
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleWindowResize);
      
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    let grid = []
    let numRows = (windowSize[1] - 2 * padding) / (ellipseGap + ellipseHeight)

    for (let i = 0; i < numRows; i ++) {
        grid.push(<Row key={i} width={windowSize[0]}/>)
    }

      /** Adds a Seat on Click, takes in 
   * @param mousePositions
   * @param selectedItem
   * @param seatArray
   * @param setSeatArray
   */
  function newSeat() {
    // Check if in bounds
    // MAKE THIS DYNAMIC
    if (state.selectedItem === 'chair') {
      let currentIndex = seatArray.length + 1;
      setSeatArray(seatArray.concat(
        {
          x: state.mousePos.x, 
          y: state.mousePos.y,
          seatNum: currentIndex,
          name: null,
          key: Date.now(),
        }
      ))
    }
  }

  return (
    <div className='grid' onClick={newSeat}>
        {grid}
    </div>
  )
}

export default Grid