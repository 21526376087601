import { shuffleArray } from "./helperFunctions";

/**
 * README:
 * 
 * This is the secret sauce behind constraint-based seating. Important to remember:
 * 
 * Objective: We want to update the added seat objects to display a name of a student.
 * 
 * Seat Object:
 * {
 *   x: position X, 
 *   y: position Y,
 *   seatNum: [1 - n],
 *   name: null,           <---- what we want to update
 * }
 * 
 * Student Object:
 * {
 *  name: '', 
 *  avoidList: [],
 *  specificSeating: []   <---- Question: do we need to store the seatNum in the student Object?
 * } 
 * 
 * Seating Assignment: 
 * Note: 1 indexed (actual seatNums)
 * {
 *  seatNum: Name,
 *  seatNum: Name,
 *  ...
 * }
 */


/**
 * Updates the app's seating
 * @param param0 
 */
export function displayNewSeatingAssignment({setSeatArray, seatArray, studentList}) {
  if (studentList !== undefined){
    const newAssignment = getSeatingAssignment({studentList, seatArray}); // gets
    const newSeating = clearSeating({seatArray}).map((seat) => {
        if (Object.keys(newAssignment).includes(seat.seatNum.toString())) {
          return {...seat, name: newAssignment[seat.seatNum]}  
        }
        return seat;
    });
    setSeatArray(newSeating);  // only output is setting the newSeating
  } 
}

/**
 * Helper Function - removes student names from existing seats
 * @param seatArray - array of currently displayed seats 
 * @returns - array of seats with no student names
 */
function clearSeating({seatArray}){
  const clearedSeating = seatArray.map((seat) => {
    return {...seat, name: null}  
  })
  return clearedSeating;
}

/**
 * Gets a Seating Assignment
 * @param studentList - List of uploaded Student Objects
 * @returns dictionary [keys = seat number : values = name to be displayed] based on algorithm
 */
function getSeatingAssignment({studentList, seatArray}) {
  // Find students with restrictions
  let studentsWithRestrictions = studentList.filter(student =>
    student.specificSeating.length !== 0);
  let studentsNoRestrictions = studentList.filter(student => 
    student.specificSeating.length === 0);

  // find first arrangement of students with restrictions
  // This array will correspond to the seats in the classroom
  // Note that arrays are zero indexed while the seats are 1 indexed
  // Filled with sentinel value -1 if empty seat
  let arrangementArray = new Array(seatArray.length).fill(-1)
  
  if (recursiveSpecificSeating(studentsWithRestrictions)) {
    // a solution was found, fill in remaining students
    return fillInRemainingStudents(studentsNoRestrictions, arrangementArray)
  } else {
    // no solution was found, return random
    // Generates a zero-indexed array representing each seat
    let openSeats = shuffleArray(Array.from(Array(seatArray.length).keys()))
    return randomSeating(studentList, openSeats)
  }

  // Need to include this helper function because it modifies the array
  function recursiveSpecificSeating(studentsToPlace){
    for (let i = 0; i < studentsToPlace.length; i++) {
      for (let j = 0; j < studentsToPlace[i].specificSeating.length; j++){
        // look through each specificSeating option for each student
        const shuffledSpecificSeating = shuffleArray(studentsToPlace[i].specificSeating)
        const possibleMove = shuffledSpecificSeating[j] - 1 // make zero-indexed
        if (arrangementArray[possibleMove] === -1) {
  
          arrangementArray[possibleMove] = studentsToPlace[i].name
  
          if (recursiveSpecificSeating(studentsToPlace.slice(i+1))){
            return true
          } else {
            arrangementArray[possibleMove] = -1
          }
        }
      }
      return false
    }
    return true
  }
}

function fillInRemainingStudents (studentsNoRestrictions, arrangementArray){
  // place each seated student into a dictionary
  // save each open seat
  let assignment = {};
  let remainingSeats = [];
  arrangementArray.forEach((studentName, index) =>{
    if (studentName === -1) {
      remainingSeats.push(index)
    } else {
      assignment[index + 1] = studentName
    }
  }) 
  
  // this is the same code as randomSeating, need to consolidate

  remainingSeats = shuffleArray(remainingSeats)
  let randomAssignment = randomSeating(studentsNoRestrictions, remainingSeats)

  assignment = Object.assign({}, randomAssignment, assignment)

  return assignment;
}

/**
 * Generates a random seating - dictionary [keys = seat number : values = name to be displayed]
 * @param studentList - list of student objects
 * @returns random seating - (dictionary [keys = seat number : values = name to be displayed])
 */
function randomSeating(studentList, openSeats) {
  let assignment = {};
  // shuffle indices of chairs (0 to n)
  openSeats = shuffleArray(openSeats)
  // Maps each student to one of the spots (adds one to one-index)
  studentList.forEach((student, index) => {
    assignment[openSeats[index] + 1] = student.name;
  });
  return assignment;
}



// Not needed ye
// // Creates a graph based on the coordinates of each seat
// function createGraph({seatArray}){
//   const numberOfSeats = seatArray.length;
//   let graph = [];
//   let x = 0;
//   let y = 0;

//   for (var i = 0; i < numberOfSeats; i++) { // run through each node
//     graph[i] = [];
//     for (var j = 0; j < numberOfSeats; j++) {
//       x = seatArray[j].x - seatArray[i].x;
//       y = seatArray[j].y - seatArray[i].y;
//       graph[i][j] = Math.sqrt(x * x + y * y)
//     }
//   }
//   return graph;
// }