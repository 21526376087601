import { createTheme } from "@mui/material";

// Grid 
export const ellipseGap = 12 // in pixels
export const padding = 12 // in pixels
export const [ellipseWidth, ellipseHeight] = [2, 2] // in pixels

// Sandbox
export const defaultItem = 'chair';

export const defaultState = {
    seePopUp: false,
    mousePos: {},
    selectedItem: defaultItem,
    lastChairs: {},
    currentClassRoomIndex: 0,
  }
  
 export const defaultClassRoomList = ['Page 1', 'Page 2']

 export const defaultTheme = createTheme();