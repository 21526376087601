import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sandbox from './pages/Sandbox';
import Authentication from './pages/Authentication';
import Landing from './pages/Landing'
import React, {useState, useEffect} from 'react'
import { supabase } from './utils/supabaseClient';

function App() {
  const [session, setSession] = useState(null)
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      
    })

    return () => subscription.unsubscribe()
  }, [])
  
  if (!session) { // not authenticated
    return(
      <Router>
        <Routes>
          <Route index exact path='/' element={<Landing />} />
          <Route exact path='/signin' element={<Authentication/>}/>
        </Routes>
      </Router>
    );
  } else { // authenticated
    
    return(
      <Sandbox />
    )
  }
}

export default App;
