import React, {useState, Fragment} from 'react'
import './ToolBar.css'
import Splitter from '../Splitter'
import Icon from './Icon'
import rotate_panel from '../../images/icons/icon_rotate_panel.svg'
import { toolbarItems } from './toolbarHelpers'

// TODO: 
// Make Dynamic
// Add tool tips
// Add Functionality to each button

function ToolBar({state, setState, seatArray, setSeatArray, studentList}) {
  const splitterLocations = [2, 5, 7, 10];
  const buttonItems = {'undo': 0, 'redo': 1, 'upload': 8, 'shuffle': 10}
  const [isVertical, setIsVertical] = useState(false);

  function changeSelection(name, index) {
    if (Object.keys(buttonItems).includes(name) && toolbarItems[index].action !== null){
      // If it's one of the buttons, don't change selectedItem, but do execute the action
      toolbarItems[index].action({state, setState, seatArray, setSeatArray, studentList});
    } else {
      // Otherwise, update the selected Item and finish
      setState(prevState => ({...prevState, selectedItem: name}))
    }
  }

  return (
    <span className={`toolbar ${isVertical ? "vertical" : ""}`}>
      {toolbarItems.map((item, index) => 
      <Fragment key={index}>
        {(splitterLocations.includes(index)) && <Splitter /> }
            <Icon 
              item={item}
              index={index}
              selected={(item.name === state.selectedItem)}
              onClick={(item.name !== state.selectedItem) ? () => changeSelection(item.name, index) : null}
              style={isVertical ? {transform: 'rotate(-90deg)', transition: 'linear 0.5s'}: {transition: 'linear 0.5s'}}
              />
      </Fragment>
      )}
      <img 
        src={rotate_panel} 
        alt='Click to Rotate Panel' 
        className={isVertical ? `switch--side`: `switch--top`}
        onClick={() => setIsVertical(!isVertical)}
        />
    </span>

    )
}

export default ToolBar