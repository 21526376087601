import React, {useState} from 'react'
import './Sidebar.css'
import arrow from '../../images/icons/icon_arrow.svg'

// TO-DO:
// Add in classroom subtraction functionality

function ClassList({classRoomList, state, setState, setClassRoomList, showSideBar}) {
  return(
    <>
    {classRoomList.map((person, index) => (
      <div 
      className={`classItem 
        ${(state.currentClassRoomIndex === index) ? "currentItem ": ""}
        ${showSideBar ? "" : "hiddenClassItem"}`} 
      key={index}
      onClick={()=>setState(newState => ({...newState, currentClassRoomIndex: index}))}>
        {person}</div>
    ))}
    <div className={`classItem ${showSideBar ? "" : "hiddenClassItem"}`} 
      onClick={()=>setClassRoomList([...classRoomList, `Page ${classRoomList.length + 1}`])}>
        Add +</div>
    </>
  )

}

function Sidebar({classRoomList, setClassRoomList, state, setState}) {
  const [showSideBar, setShowSideBar] = useState(true);
  
  function HorizonSplitter(){
    return (
      <div className={`horizon-splitter ${showSideBar ? "" : "hiddenSplitter"}`}></div>
    )
  }
  
  return (
    <div className={`sidebar ${showSideBar ? "" : "hiddenSideBar"}`}>
      <div className="title">Pages</div>
      <HorizonSplitter />
      <img src={arrow} className={`sideArrow ${showSideBar ? "" : "hiddenArrow"}`} alt="arrow icon" onClick={()=>setShowSideBar(!showSideBar)}/>
      <div className="classList">
        <ClassList 
          classRoomList={classRoomList}
          state={state} 
          setState={setState}
          setClassRoomList={setClassRoomList}
          showSideBar={showSideBar}/>
      </div>
    </div>

    )
}

export default Sidebar