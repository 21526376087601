import React, {useRef, useState} from 'react'
import './PopUp.css'
import {cleanInput, convertListToStudents, isEnoughSeats } from './popUpHelpers'
import { Button } from '@mui/material'
import Draggable from 'react-draggable'

/**
 * 
 * @param {*} param0 
 * @returns A PopUp Component
 */
function PopUp({numSeats, state, setState, defaultItem, setStudentList}) {
    const popUpText = {
        title: "Upload Names!",
        caption: `List your student's names here and we'll automatically add them to the existing seats. Number of seats: ${numSeats}`,
        buttonText: "Import Names"
    }
    const instructions = `Please separate the names that should not be next to each other with - , write the each name on a new line, and indicate the number in front of the name for an exact seat:
    Justin - D'angelo
    2. 4. Lindsay
    Hope`
    const studentInputBox = useRef(null);
    const [misMatch, setMisMatch] = useState(0);

    function handleUpload(){ 
        // Gets and cleans user input
        var studentInput = studentInputBox.current.value.split('\n');
        studentInput = cleanInput(studentInput);
        // update student box with cleaned input
        studentInputBox.current.value = studentInput.join('\n');

        // Uploads if enough seats
        if (isEnoughSeats(studentInput, numSeats, setMisMatch)) {
            let studentObjects = convertListToStudents(studentInput);

            setState(newState => ({...newState, seePopUp: false, selectedItem: defaultItem}))
            setStudentList([...studentObjects])
        }
    }

    function hidePopUp() {
        setState(newState => ({...newState, seePopUp: false, selectedItem: defaultItem}))
        setMisMatch(0);
    }
    
    return (
        <div className={`popup--wrapper ${state.seePopUp ? "" : "hidden--popup"}`}>
            <Draggable>
                <div className={`popup--container 
                    ${state.seePopUp ? "" : "hidden--popup"}`}>
                    <svg className="x-out" onClick={hidePopUp} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                    </svg>
                    <p className="popup--title">{popUpText.title}</p>
                    <p className="caption">{popUpText.caption}</p>
                    <p className={`mismatch-alert ${(misMatch !== 0) ? "visible":""}`}>Not enough seats, please remove {misMatch} name{(misMatch === 1) ? "" : "s"}.</p>
                    
                    <textarea name="students" ref={studentInputBox} className="textbox" id="" cols="30" rows="10"
                        placeholder={instructions}>
                    </textarea>
            
                    <Button variant="contained" className="button" onClick={handleUpload}>{popUpText.buttonText}</Button>

                </div>
            </Draggable>
            <div className={`shading ${state.seePopUp ? "" : "hidden--popup"}`}></div>
        </div>
    )
  }

export default PopUp