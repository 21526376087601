import React, { useRef } from 'react'
import './Seat.css'
// import { useState } from 'react';
import Draggable from 'react-draggable';
const offsetX = 70;
const offsetY = 50;

// TODO: 
// Set the boundaries for the movement

function SeatLabel(name) {
  return(
    <div className="label">
      {name}
    </div>
  )
}

function Seat(props) {
  const x_pos = props.x - offsetX;
  const y_pos = props.y - offsetY;
  const nodeRef = useRef(null);

  // Updates Seat Location in the Sandbox's Seat Array
  function updateSeatLocation(seatNumber, new_x, new_y) {
    const tempSeatArray = props.seatArray.map((seat) => {
      if (seat.seatNum === seatNumber) {
        return {...seat, x: seat.x + new_x, y: seat.y+ new_y}
      } else {
        return seat;
      }
    })
    props.setSeatArray(tempSeatArray);
  }

  // When the Seat is moved, update the location in the sandbox's seatArray
  function handlePositionChange(e, ui) {
    const {x, y} = ui;
    updateSeatLocation(props.seatNum, x - props.x + offsetX, y - props.y + offsetY)
  }

  /**
   * Removes seat when clicked on if eraser is selected
   *  Updates remaining seats 
   */
  function removeSelf() {
    // filter out the current seat
    let reorderedArray = props.seatArray.filter(seat => 
        seat.seatNum !== props.seatNum
    )
    // reorder everything 
    reorderedArray = reorderedArray.map((seat, index) => 
        seat = {...seat, seatNum: index + 1}
    )
    // update the seatArray
    props.setSeatArray(reorderedArray)
  }

  const handleClick = props.selectedItem === "eraser" ? () => removeSelf() : ()=>{};

  return (
    <div nodeRef={nodeRef} >
      <Draggable 
        // positionOffset={{x: x_pos, y: y_pos}}
        // disabled={props.selectedItem !== 'chair'}
        onStop={handlePositionChange}
        // nodeRef={nodeRef}
        defaultPosition={{x: x_pos, y: y_pos}}
        >
        <div         
          onClick={handleClick}
          className="seat--container" 
            // style={{'left': x_pos,'top': y_pos }}
        >
            <div className="seat--number">
                {props.seatNum}
            </div>
            {(props.name !== null) ? SeatLabel(props.name) : <></>}
        </div>
      </Draggable>
    </div>
  )
}

export default Seat