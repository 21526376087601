import React, { useEffect, useState }  from 'react'
import { supabase } from '../utils/supabaseClient'
import { displayNewSeatingAssignment } from '../utils/seatingGeneration'
import { defaultState, defaultItem, defaultClassRoomList } from '../utils/constants'
import Grid from '../components/Sandbox/Grid'
import Menu from '../components/Sandbox/Menu'
import ToolBar from '../components/Sandbox/ToolBar'
import Sidebar from '../components/Sandbox/Sidebar'
import Seat from '../components/Sandbox/Seat'
import PopUp from '../components/Sandbox/PopUp'
import './Sandbox.css'

function Sandbox() {
  // User-Determined + Edited
  const [user, setUser] = useState([]); 
  const [seatArray, setSeatArray] = useState([]); // Array of Seat Objects - captured in user object
  const [studentList, setStudentList] = useState(undefined); // this will also be replaced by the user object
  const [classRoomList, setClassRoomList] = useState(defaultClassRoomList); // this will be replaced by the user object

  // App Functionality -> modified by ToolBar, Hooks
  const [state, setState] = useState(defaultState);

  /** Gets the User Information from Supabase */
  useEffect (() => {

    async function getClassroomData(id){
      let { data: classrooms, error } = await supabase
        .from('classrooms')
        .select('classroom_name')
        .eq('user', id)

      if (error) {
        console.log("Problem retrieving classrooms")
        return 0
      } else {
        return classrooms
      }
    } 

    async function uploadClassroomData(id){
      const classrooms = getClassroomData(id)
      console.log(classrooms)
      if (classrooms !== 0 ){ // if no error
        if (classrooms.length === 0){
          const { error } = await supabase
            .from('classrooms')
            .insert({ 'user': id, 'classroom_name': 'Page 1'})
        }
      }
      
    }

    async function getUserData(){
      await supabase.auth.getUser().then((value) => {
        if (value.data?.user) {
          setUser(value.data.user)
          uploadClassroomData(value.data.user.id)
        } 
      }) }
    getUserData();
  },[]);

  /** Tracks location of the mouse */
  useEffect(() => {
    const handleMouseMove = (event) => {
      const newPos = {x: event.clientX, y: event.clientY}
      setState(newState => ({...newState, mousePos: newPos}));
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove',handleMouseMove);
    };
  }, [state]);

  /** Updates seating after every student import */
  useEffect(() => {
    displayNewSeatingAssignment({setSeatArray, seatArray, studentList});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentList]);

  /** Signs Out the User on Click */
  async function signOutUser(){
    await supabase.auth.signOut();
  };

  return (
    <div className="sandbox">
        {seatArray.map((seat) => {
          return (
          <Seat 
            x={seat.x} 
            y={seat.y}
            seatNum={seat.seatNum}
            name={seat.name}
            key={seat.key} 
            selectedItem={state.selectedItem}
            seatArray={seatArray}
            setSeatArray={setSeatArray}/>
          )
        })}
        <PopUp 
          numSeats={seatArray.length}
          state={state}
          setState={setState}
          defaultItem={defaultItem}
          setStudentList={setStudentList}
          />
        <Menu 
          signOutUser={signOutUser}/>
        <Sidebar 
          classRoomList={classRoomList}
          setClassRoomList={setClassRoomList}
          state={state}
          setState={setState}
          />
        <ToolBar 
          state={state}
          setState={setState}
          seatArray={seatArray}
          setSeatArray={setSeatArray}
          studentList={studentList}
          />
        <Grid 
          state={state}
          seatArray={seatArray}
          setSeatArray={setSeatArray}/>
    </div>
  )
}

export default Sandbox