import React from "react";
import BannerBackground from "../../images/home-banner-background.png";
import BannerImage from "../../images/desks_compress.jpg";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";


const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="home-container" id="testTag">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground}alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Better Than Random
          </h1>
          <p className="primary-text">
          Separate the talkative, focus the unattentive, and add preferential seatings to keep your students on-task. 
          </p>
          <button onClick={()=>navigate('/signin')} className="secondary-button">
            Make a Seating Chart<FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} className="home-image" alt="Students in desks" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
