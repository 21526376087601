import React from 'react'
import './Menu.css'
import logo from '../../images/chair_chart_logo.png'
import arrow from '../../images/icons/icon_arrow.svg'
import Splitter from '../Splitter'
import { useState } from 'react'

function Menu(props) {
  const [showResults, setShowResults] = useState(true)
  
  const hideMenu = () => {
    setShowResults(!showResults)
  }

  return (
    <div className={`menu ${showResults ? "" : "menu--small"}`}>
      <img src={logo} className="logo" alt="ChairChart logo"/>
      
      <svg className={`menu--icon ${showResults ? "" : "hidden"}`} width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.08751 14.6118V11.7547H6.23036C5.63661 11.7547 5.15894 11.277 5.15894 10.6832C5.15894 10.0895 5.63661 9.6118 6.23036 9.6118H9.08751V6.75466C9.08751 6.16091 9.56519 5.68323 10.1589 5.68323C10.7527 5.68323 11.2304 6.16091 11.2304 6.75466V9.6118H14.0875C14.6813 9.6118 15.1589 10.0895 15.1589 10.6832C15.1589 11.277 14.6813 11.7547 14.0875 11.7547H11.2304V14.6118C11.2304 15.2055 10.7527 15.6832 10.1589 15.6832C9.56519 15.6832 9.08751 15.2055 9.08751 14.6118ZM0.158936 3.54037C0.158936 1.96225 1.43795 0.683228 3.01608 0.683228H17.3018C18.8777 0.683228 20.1589 1.96225 20.1589 3.54037V17.8261C20.1589 19.402 18.8777 20.6832 17.3018 20.6832H3.01608C1.43795 20.6832 0.158936 19.402 0.158936 17.8261V3.54037ZM2.30179 3.54037V17.8261C2.30179 18.2189 2.62144 18.5404 3.01608 18.5404H17.3018C17.6947 18.5404 18.0161 18.2189 18.0161 17.8261V3.54037C18.0161 3.14573 17.6947 2.82608 17.3018 2.82608H3.01608C2.62144 2.82608 2.30179 3.14573 2.30179 3.54037Z" fill="#6EC2FF"/>
      </svg>
 
      <Splitter visible={showResults} />

      <svg className={`menu--icon ${showResults ? "" : "hidden"}`} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6589 10.4492C9.08751 10.4492 7.80179 11.7349 7.80179 13.3064C7.80179 14.8778 9.08751 16.1635 10.6589 16.1635C12.2304 16.1635 13.5161 14.8778 13.5161 13.3064C13.5161 11.7349 12.2304 10.4492 10.6589 10.4492ZM19.9938 4.78404L16.2482 1.0385C15.8866 0.675112 15.3375 0.449219 14.7705 0.449219H3.51608C1.93795 0.449219 0.658936 1.72824 0.658936 3.30636V17.5921C0.658936 19.1702 1.93795 20.4492 3.51608 20.4492H17.8018C19.3799 20.4492 20.6589 19.1702 20.6589 17.5921V6.33761C20.6589 5.77065 20.4313 5.22154 19.9938 4.78404ZM6.37322 2.59208H12.8018V6.1635H6.37322V2.59208ZM18.5161 17.5921C18.5161 17.9865 18.1963 18.3064 17.8018 18.3064H3.51608C3.12161 18.3064 2.80179 17.9865 2.80179 17.5921V3.30636C2.80179 2.91181 3.12161 2.59208 3.51608 2.59208H4.23036V7.23493C4.23036 7.82645 4.71027 8.30636 5.30179 8.30636H13.8732C14.467 8.30636 14.9447 7.82868 14.9447 7.23493V2.76529L18.438 6.25859C18.4893 6.30636 18.5161 6.37779 18.5161 6.44475V17.5921Z" fill="#6EC2FF"/>
      </svg>

      <Splitter visible={showResults} />

      <svg className={`menu--icon ${showResults ? "" : "hidden"}`} width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.5174 9.58627L20.5174 17.3006C20.2924 17.7385 19.8299 18.0117 19.2882 18.0117H3.32562C1.85269 18.0117 0.658936 16.8602 0.658936 15.4403V2.58315C0.658936 1.16324 1.85269 0.0117188 3.32562 0.0117188H8.22148C8.92856 0.0117188 9.6069 0.282603 10.1073 0.765067L12.1548 2.58315H17.9924C19.4653 2.58315 20.659 3.73467 20.659 5.15458V6.44029H18.659V5.15458C18.659 4.80004 18.3599 4.51172 17.9924 4.51172H11.3257L8.69231 2.12873C8.56731 2.00699 8.40064 1.94029 8.22148 1.94029H3.32562C2.95811 1.94029 2.65895 2.22877 2.65895 2.58315V14.1546L5.62396 8.43716C5.85063 7.99922 6.31313 7.726 6.8173 7.726H23.3257C24.3132 7.726 24.9591 8.73047 24.5174 9.58627Z" fill="#6EC2FF"/>
      </svg>

      <Splitter visible={showResults} />
      
      <svg onClick={() => props.signOutUser()} className={`menu--icon ${showResults ? "" : "hidden"}`} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5618 7.61639C15.5618 11.6819 12.2668 14.9769 8.20119 14.9769C4.1356 14.9769 0.840576 11.6819 0.840576 7.61639C0.840576 3.55085 4.1356 0.255859 8.20119 0.255859C12.2668 0.255859 15.5618 3.55085 15.5618 7.61639ZM7.74115 9.91656H7.05109C6.66869 9.91656 6.36104 10.2242 6.36104 10.6066C6.36104 10.989 6.66869 11.2967 7.05109 11.2967H9.35129C9.73369 11.2967 10.0413 10.989 10.0413 10.6066C10.0413 10.2242 9.73369 9.91656 9.35129 9.91656H9.12127V7.38638C9.12127 7.00397 8.81362 6.69633 8.43121 6.69633H7.05109C6.66869 6.69633 6.36104 7.00397 6.36104 7.38638C6.36104 7.76878 6.66869 8.07643 7.05109 8.07643H7.74115V9.91656ZM7.28111 4.85619C7.28111 5.36511 7.69227 5.77626 8.20119 5.77626C8.71011 5.77626 9.12127 5.36511 9.12127 4.85619C9.12127 4.34728 8.71011 3.93613 8.20119 3.93613C7.69227 3.93613 7.28111 4.34728 7.28111 4.85619ZM8.20111 13.5836C11.4968 13.5836 14.1685 10.912 14.1685 7.61633C14.1685 4.32069 11.4968 1.64905 8.20111 1.64905C4.90544 1.64905 2.23377 4.32069 2.23377 7.61633C2.23377 10.912 4.90544 13.5836 8.20111 13.5836Z" fill="#6EC2FF"/>
      </svg>

      <img src={arrow} onClick={hideMenu} className={`arrow ${showResults ? "": "arrow--hidden" }`} alt='Info Icon'/>

    </div>
  )
}

export default Menu