import { displayNewSeatingAssignment } from "../../utils/seatingGeneration"

/**
 * This captures all of the names and actions of each icon in the toolbar
 */
export const toolbarItems = [
  {name: 'undo', action: handleUndo, tooltip: 'Undo last move', path: "M2.44993 12.8673H1.9004C1.04053 12.8673 0.348755 12.1509 0.348755 11.2604V2.69016C0.348755 2.0407 0.723735 1.4515 1.3056 1.20377C1.88747 0.956034 2.55338 1.08994 2.99947 1.55193L5.68898 4.33725C11.3525 -1.45434 20.4748 -1.43425 26.106 4.40421C31.763 10.2628 31.763 19.7569 26.106 25.6155C20.449 31.474 11.2814 31.474 5.62433 25.6155C4.81619 24.7786 4.81619 23.4194 5.62433 22.5824C6.43248 21.7455 7.74491 21.7455 8.55305 22.5824C12.5938 26.7671 19.143 26.7671 23.1837 22.5824C27.2245 18.3978 27.2245 11.6152 23.1837 7.43056C19.1624 3.26598 12.6649 3.24589 8.61771 7.36361L11.2749 10.1221C11.721 10.5841 11.8503 11.2738 11.6111 11.8764C11.3719 12.479 10.8029 12.8673 10.1758 12.8673H2.44993Z", viewBox: "0 0 31 31"},
  {name: 'redo', action: handleRedo, tooltip: 'Redo last move', path: "M28.4332 12.8673H28.9828C29.8427 12.8673 30.5344 12.1509 30.5344 11.2604V2.69016C30.5344 2.0407 30.1594 1.4515 29.5776 1.20377C28.9957 0.956034 28.3298 1.08994 27.8837 1.55193L25.1942 4.33725C19.5307 -1.45434 10.4084 -1.43425 4.77719 4.40421C-0.879832 10.2628 -0.879832 19.7569 4.77719 25.6155C10.4342 31.474 19.6018 31.474 25.2588 25.6155C26.067 24.7786 26.067 23.4194 25.2588 22.5824C24.4507 21.7455 23.1383 21.7455 22.3301 22.5824C18.2894 26.7671 11.7402 26.7671 7.69945 22.5824C3.65872 18.3978 3.65872 11.6152 7.69945 7.43056C11.7208 3.26598 18.2183 3.24589 22.2655 7.36361L19.6083 10.1221C19.1622 10.5841 19.0329 11.2738 19.2721 11.8764C19.5113 12.479 20.0802 12.8673 20.7074 12.8673H28.4332Z", viewBox: "0 0 31 31"}, 
  // {name: 'move', action: null, tooltip: 'Undo last move'},
  // {name: 'pencil', action: null, tooltip: 'Coming soon!'}, 
  // {name: 'shapes', action: null, tooltip: 'Coming soon!'},
  // {name: 'text', action: null, tooltip: 'Coming soon!'},
  {name: 'eraser', action: null, tooltip: 'Erase seats', path: "M15.7905 1.28419L1.94073 17.1459C0.456607 18.8457 0.456607 21.5992 1.94073 23.2989L6.68991 28.738C7.40229 29.5539 8.36993 30.0094 9.37913 30.0094H15.6302H16.1883H28.928C29.9787 30.0094 30.8276 29.0372 30.8276 27.8338C30.8276 26.6304 29.9787 25.6581 28.928 25.6581H21.5608L29.3198 16.7788C30.8039 15.0791 30.8039 12.3255 29.3198 10.6258L21.169 1.28419C19.6849 -0.41553 17.2806 -0.41553 15.7965 1.28419H15.7905ZM16.1883 25.6649H15.6302H9.3732L4.62402 20.2258L12.0268 11.7476L20.1835 21.0893L16.1883 25.6649Z", viewBox: "0 0 31 31" }, 
  {name: 'chair', action: null, tooltip: 'Place seats', path: "M17.4349 3.18347V15.371H20.6494V3.81042C22.25 4.61902 23.3281 6.1366 23.3281 7.87097V15.371H26.5427V7.87097C26.5427 3.72839 22.7053 0.370972 17.9706 0.370972H13.6846C8.94993 0.370972 5.11262 3.72839 5.11262 7.87097V15.371H8.32712V7.87097C8.32712 6.1366 9.40532 4.61902 11.0059 3.81042V15.371H14.2204V3.18347H17.4349ZM4.04112 17.246C3.23079 17.246 2.48744 17.6444 2.12581 18.2831L1.05431 20.1581C0.719461 20.7382 0.759642 21.4296 1.14806 21.9803C1.53648 22.5311 2.22626 22.871 2.96961 22.871V28.496C2.96961 29.5331 3.92727 30.371 5.11262 30.371C6.29797 30.371 7.25562 29.5331 7.25562 28.496V22.871H24.3997V28.496C24.3997 29.5331 25.3573 30.371 26.5427 30.371C27.728 30.371 28.6857 29.5331 28.6857 28.496V22.871C29.429 22.871 30.1188 22.537 30.5072 21.9803C30.8956 21.4237 30.9358 20.7382 30.601 20.1581L29.5295 18.2831C29.1678 17.6444 28.4245 17.246 27.6142 17.246H4.04112Z", viewBox: "0 0 31 31"},
  {name: 'upload', action: showPopUp, tooltip: 'Upload student names', path: "M17.7026 6.77412V20.9955C17.7026 22.0327 16.8647 22.8706 15.8276 22.8706C14.7905 22.8706 13.9526 22.0327 13.9526 20.9955V6.77412L9.65186 11.0751C8.91943 11.8076 7.72998 11.8076 6.99756 11.0751C6.26514 10.3427 6.26514 9.15314 6.99756 8.42068L14.4976 0.920315C15.23 0.187857 16.4194 0.187857 17.1519 0.920315L24.6519 8.42068C25.3843 9.15314 25.3843 10.3427 24.6519 11.0751C23.9194 11.8076 22.73 11.8076 21.9976 11.0751L17.7026 6.77412ZM4.57764 20.9955H12.0776C12.0776 23.064 13.7593 24.7457 15.8276 24.7457C17.896 24.7457 19.5776 23.064 19.5776 20.9955H27.0776C29.146 20.9955 30.8276 22.6772 30.8276 24.7457V26.6208C30.8276 28.6892 29.146 30.371 27.0776 30.371H4.57764C2.50928 30.371 0.827637 28.6892 0.827637 26.6208V24.7457C0.827637 22.6772 2.50928 20.9955 4.57764 20.9955ZM26.1401 27.0896C26.9194 27.0896 27.5464 26.4626 27.5464 25.6832C27.5464 24.9039 26.9194 24.2769 26.1401 24.2769C25.3608 24.2769 24.7339 24.9039 24.7339 25.6832C24.7339 26.4626 25.3608 27.0896 26.1401 27.0896Z", viewBox: "0 0 31 31"},
  // {name: 'lock', action: null, tooltip: 'Lock student in place'},
  {name: 'shuffle', action: displayNewSeatingAssignment, tooltip: 'Shuffle seating', path: "M23.9731 2.88656C24.6763 2.59359 25.479 2.75766 26.0181 3.29086L29.7681 7.04086C30.1196 7.39242 30.3188 7.86703 30.3188 8.36508C30.3188 8.86313 30.1196 9.33773 29.7681 9.6893L26.0181 13.4393C25.479 13.9784 24.6763 14.1366 23.9731 13.8436C23.27 13.5506 22.813 12.8709 22.813 12.1092V10.2459H20.938C20.3462 10.2459 19.7896 10.5213 19.438 10.9959L16.9536 14.3065L14.6099 11.1834L16.438 8.74594C17.4985 7.32797 19.1685 6.49594 20.938 6.49594H22.813V4.62094C22.813 3.86508 23.27 3.17953 23.9731 2.88656ZM9.92236 17.4354L12.2661 20.5584L10.438 22.9959C9.37744 24.4139 7.70752 25.2459 5.93799 25.2459H2.18799C1.15088 25.2459 0.312988 24.408 0.312988 23.3709C0.312988 22.3338 1.15088 21.4959 2.18799 21.4959H5.93799C6.52979 21.4959 7.08643 21.2205 7.43799 20.7459L9.92236 17.4354ZM26.0122 28.451C25.4731 28.9901 24.6704 29.1483 23.9673 28.8553C23.2642 28.5623 22.8071 27.8827 22.8071 27.1209V25.2459H20.938C19.1685 25.2459 17.4985 24.4139 16.438 22.9959L7.43799 10.9959C7.08643 10.5213 6.52979 10.2459 5.93799 10.2459H2.18799C1.15088 10.2459 0.312988 9.40805 0.312988 8.37094C0.312988 7.33383 1.15088 6.49594 2.18799 6.49594H5.93799C7.70752 6.49594 9.37744 7.32797 10.438 8.74594L19.438 20.7459C19.7896 21.2205 20.3462 21.4959 20.938 21.4959H22.813V19.6209C22.813 18.8651 23.27 18.1795 23.9731 17.8866C24.6763 17.5936 25.479 17.7577 26.0181 18.2909L29.7681 22.0409C30.1196 22.3924 30.3188 22.867 30.3188 23.3651C30.3188 23.8631 30.1196 24.3377 29.7681 24.6893L26.0181 28.4393L26.0122 28.451Z", viewBox: "0 0 31 31"}
]

/** 
 * Handles Undo - removes last placed chair and saves it 
 * @param seatArray - array of seat objects
 * @param setSeatArray - updates seatArray by removing last chair
 * @param setState - updates the last chair in the sandbox state
 */  
export function handleUndo({seatArray, setSeatArray, setState}){
    if (seatArray.length !== 0){
      const newArray = [...seatArray.slice(0, seatArray.length)]
      setState(newState => ({...newState, lastChairs: newArray.pop()}))
      setSeatArray(newArray)
    }
  }

/**
 * Handles Redo - adds last erased seat back
 * @param seatArray - array of seat objects
 * @param setSeatArray - modifier for seat object array
 * @param state - captures current state of app
 * @param setState - modifies current state of app
 */
export function handleRedo({seatArray, setSeatArray, state, setState}){
    if (state.lastChairs !== undefined) {
      setSeatArray([...seatArray, state.lastChairs]);
      setState(newState => ({...newState, lastChairs: undefined}))
    }
  }

/**
 * Handles PopUp - shows the PopUp for User Input
 * @param setState - sets state to show PopUp 
 */
export function showPopUp({setState}) {
  setState(newState => ({...newState, seePopUp: true}));
}