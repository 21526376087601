import React from 'react'
import Hero from "../components/Landing/Hero";
import About from "../components/Landing/About";
import Work from "../components/Landing/Work";
import Testimonial from "../components/Landing/Testimonial";
import Contact from "../components/Landing/Contact";
import Footer from "../components/Landing/Footer";
import './Landing.css'
// import { supabase } from '../utils/supabaseClient';

function Home() {
  // getUser();
  return (
    <div className="App">
        <Hero />
        {/* <About /> */}
        <Work />
        {/* <Testimonial /> */}
        <Contact />
        <Footer />
    </div>
  )
}

export default Home