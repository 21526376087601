import React from 'react'
import SignInSide from '../components/SignInSide'

function Authentication() {
  return (
    <>
    <SignInSide />
    </>
  )
}

export default Authentication