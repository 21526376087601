import React from 'react'
import './Icon.css'
import { IconButton, Tooltip, SvgIcon } from '@mui/material';

function Icon({item, selected, onClick, index, style}) {
  return (
    <IconButton onClick={() => {onClick && onClick(item.name, index)}}>
        <Tooltip 
            title={item.tooltip} 
            enterDelay={50}
            arrow>
            <SvgIcon 
                className={`icon ${selected && 'selected--icon'}`}
                fontSize="small"
                viewBox={item.viewBox}
                style={style}>
                <path d={item.path}/>
            </SvgIcon>
        </Tooltip>
    </IconButton>
  )
}

export default Icon