/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from '../../images/chair_chart_logo.png'
import { HiOutlineBars3 } from "react-icons/hi2";
import { Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "About",
      icon: <InfoIcon />,
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
    },
  ];

  function handleClick() {
    navigate('/signin')
  }
  
  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" width="100px"/>
      </div>
      <div className="navbar-links-container">
        <HashLink smooth to="#home">Home</HashLink>
        <HashLink smooth to="#about">About</HashLink>
        {/* <a href="">Testimonials</a> */}
        <HashLink smooth to="#contact">Contact</HashLink>
        {/* <a href="">
          <BsCart2 className="navbar-cart-icon" />
        </a> */}
        <button className="primary-button" onClick={handleClick}>Sign-In</button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <HashLink style={{ textDecoration: 'none' }} smooth to={`#${item.text.toLowerCase()}`}>
                <ListItem key={item.text} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} 
                        primaryTypographyProps={{
                          color: '#197abf',
                          fontWeight: 'medium',
                          variant: 'body2',
                        }}/>
                    </ListItemButton>
                </ListItem>
              </HashLink>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
