import React from "react";
import { Button } from "@mui/material";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  return (
    <div className="contact-page-wrapper" id="contact">
      <h1 className="primary-heading">Quiet Your Classroom</h1>
      <div className="contact-form-container">
        <p className="primary-text">
          Ready to create the perfect seating chart?
        </p>
        <button onClick={()=>navigate('/signin')} className="secondary-button">
            Let's go!<FiArrowRight />{" "}
        </button>      </div>
    </div>
  );
};

export default Contact;
