import React from "react";
import PickMeals from "../../images/pick-meals-image.png";
import ChooseMeals from "../../images/choose-image.png";
import DeliveryMeals from "../../images/delivery-image.png";
import Chairs from '../../images/chairs.png'
import People from '../../images/people.png'
import Clock from '../../images/time.png'

const Work = () => {
  const workInfoData = [
    {
      image: Chairs,
      title: "Recreate your classroom",
      text: "Drag and drop seats to recreate your own seating arrangement",
    },
    {
      image: People,
      title: "Add seating preferences",
      text: "Separate talkative groups, support ESL students, and add preferential seatings",
    },
    {
      image: Clock,
      title: "Focus on teaching",
      text: "Turn hours of planning into minutes",
    },
  ];
  return (
    <div className="work-section-wrapper" id="about">
      <div className="work-section-top">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">What is it?</h1>
        <p className="primary-text">
        The only seating chart that accommodates preferential seating
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} width="30%" alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
