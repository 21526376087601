/**
 * Processes the User's Input (cleans and converts to student object)
 * @param studentList Array of Cleaned User Input (each item is a line)
 * @returns an array of student objects {name: '', avoidList: []} 
 */
 export function convertListToStudents(studentList){ 
    const numberOfStudents = studentList.length
    let processedList = [];
    let textToProcess =[]; // temporary working variable

    // TODO - 
    // Enable avoid lists (remember that '-' could be in a name)
    
    // Format is as follow: 1 2 3 4 Kasen Stephensen - Hope Jason
    for (var i = 0; i < numberOfStudents; i++) {
      let student = {};
      let tempSpecificSeatList = [];
      let studentName = ""

      textToProcess = studentList[i].trim().split(' ');
      for (var j = 0; j < textToProcess.length; j++) {
        // if current string is a number, add to specific seat list
        if (!isNaN(textToProcess[j])){
          tempSpecificSeatList.push(Number(textToProcess[j]));
        } else {
        // otherwise, add to student's name
          studentName = studentName.concat(" ", textToProcess[j])
        }
      }
      if (studentName !== "") {
        // create student object based on the parsing
        student['specificSeating'] = tempSpecificSeatList;  
        student['name'] = studentName.trim();  
        // save student object
        processedList.push(student);
      } 
    }
    return processedList;
}

/**
 * Verifies that the user gave some inputs
 * @param userInput - cleaned array of user input
 * @param numSeats - number of placed seats
 * @param setMisMatch - used to capture a mismatch in the number of seats vs students
 * @returns True if acceptable input / False if not
 * TODO - iterate through every row and see if there are any non-empty rows
 */
export function isEnoughSeats(userInput, numSeats, setMisMatch) {
    // Check if there are too many names and not enough seats
    if (userInput.length > numSeats) {
        setMisMatch(userInput.length - numSeats);
        return false;
    } else {
        setMisMatch(0);
        return true;
    }
}

/**
 * Helper Function - cleans each line of a user input array
 * @param textInput - User's input converted to array
 * @returns  - Cleaned Array
 */
export function cleanInput(textInput) {
  // Remove unnecessary characters from each line
  textInput = textInput.map(line => cleanText(line))
  // pop off each line that is empty
  textInput = textInput.filter(text => 
    text !== ""
  )

  return textInput;
}

/**
 * Helper Function - cleans a string; leaves only `-` and alphabet
 * @param text - string
 * @returns cleaned string
 */
function cleanText(text) {
    let cleanedText = text.replace(/[.,/\\#!$%^&*;:{}=_`~()]/g,"").replace().replace(/\s{2,}/g," ").trim();
    return cleanedText;
}